
.error .max-width p {
  font-size: 14px;
  color: #999;
}

.error .max-width a {
  text-decoration: none;
  color: #ff0000;
}

.error .title::after {
  background: #111;
  content: "This Page is not Found";
}